import React, { Component, useEffect, useState } from "react"
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import Link from '../utils/link'
import {useKeenSlider} from "keen-slider/react";
import { Reel, Grid } from '../components/icons'
import { GatsbyImage } from "gatsby-plugin-image"
import { splitText } from "../utils/split-text"
import Skeleton from 'react-loading-skeleton'

const HomePage = ({ data }) => {
  const animation = { duration: 30000, easing: (t) => t }
  const [videoLoaded, setVideoLoaded] = useState(false);

  const { page, posts } = data

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: {
      perView: 1.5,
      spacing: 10,
      renderMode: "performance",
      drag: true,
    },
    breakpoints: {
      "(min-width: 900px)": {
        slides: {
          perView: 3,
          spacing: 40,
        },
      },
    },
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  })

  return (
    <>
      <Seo
        path={page?.slug === "films" ? "/" : `/${page?.slug}/`}
        title={he.decode(page.seo.title)}
        description={page.seo.metaDesc}
        keywords={page.seo.metaKeywords}
        schema={page.seo.schema.raw}
        bodyClass={page.slug}
      />
      <div
        className="projects__view"
      >
        <Link to='/films?view=Reel' className='projects__view-btn'>{splitText('REEL')}</Link>
        <Link to='/films?view=Grid' className='projects__view-btn'>{splitText('GRID')}</Link>
      </div>

      <section className="landing-grid">
        <div className="landing-grid__inner">
          <div ref={sliderRef} className={`landing-grid__items keen-slider${videoLoaded ? ' loaded' : ''}`}>
            {page.acf.projects?.length > 0 &&
              page.acf.projects.map((el, i) => (
                <React.Fragment key={i}>
                  <Link
                    to={el.uri}
                    className="landing-grid__item keen-slider__slide">
                    <div className='landing-grid__item-wrapper'>
                      {!videoLoaded && <Skeleton className='skeleton-wrapper' />}
                      {el.acf.video && (<video onLoadedData={() => setVideoLoaded(true)} src={el.acf.video} autoPlay loop muted playsInline />)}
                      {!el.acf.video && el.featuredImage && (<GatsbyImage image={el.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData} alt={el.title} />)}
                      <h4>{el.title}</h4>
                    </div>
                  </Link>
                </React.Fragment>))}
          </div>
        </div>
      </section>

      <section className="footer">
        <div className="footer__inner">
          <ul>
            <li>
              <Link to="/about/">{splitText('About')}</Link>
            </li>
            <li>
              <Link to="/films">{splitText('Films')}</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/photos/">{splitText('Photos')}</Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/xmedia/">{splitText('Instagram')}</Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

export const homeQuery = graphql`
  query {
    posts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Films" } } } }
      }
    ) {
      nodes {
        title
        slug
        link
        acf {
          video
          tag
          gallery {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1200
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1200
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
    page: wpPage(slug: { eq: "home" }) {
      title
      slug
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      acf {
        projects {
          ... on WpPost {
            id
            uri
            title
            slug
            acf {
              video
              videoMobile
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1600)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HomePage